<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Alejandro Orozco Marulanda                                    ###### -->
<!-- ###### @date: Septiembre 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-autocomplete
    class="me-2"
    outlined
    dense
    hide-details
    color="primary"
    v-bind="filteredAttrs"
    v-on="filteredListeners"
    clear-icon="clear"
    :clearable="isClareable"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'FilterCustomized',
  computed: {
    filteredAttrs() {
      const { ...attrs } = this.$attrs;
      return attrs;
    },
    filteredListeners() {
      const { ...listeners } = this.$listeners;
      return listeners;
    },
    isClareable(){
      const { readonly } = this.$attrs;
      const clareable = !readonly;

      return clareable;
    }
  },
};
</script>