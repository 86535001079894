/*##################################################################################
###### HERINCOHAN                                                             ######
###### @author: Alejandro Orozco M                                            ######
###### @date: Diciembre 2024                                                  ######
##################################################################################*/
 
//campo obligatorio
export const required = (value) => {
  return value ? true : "Campo obligatorio.";
}
 